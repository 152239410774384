<template>
  <div class="projects">
    <div v-if="projectsList && projectsList.length" class="projects__header">
      <div class="projects__header--left">
        <div class="projects__title">
          Проекты
        </div>
        <div class="projects__sort" :class="{'projects__sort-reverse' : sort === 'desc'}" @click="changeSorting">
          По дате создания
          <img src="../assets/icons/icon-arrow-down-grey.svg" alt="">
        </div>
      </div>
      <div class="projects__view">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="view = 'table'">
          <rect width="24" height="24" rx="6" :fill="view === 'table' ? 'white' : '#C8D0D6'"/>
          <rect x="6" y="6" width="5" height="5" :fill="view === 'table' ? '#C8D0D6' : '#fff'"/>
          <rect x="6" y="13" width="5" height="5" :fill="view === 'table' ? '#C8D0D6' : '#fff'"/>
          <rect x="13" y="6" width="5" height="5" :fill="view === 'table' ? '#C8D0D6' : '#fff'"/>
          <rect x="13" y="13" width="5" height="5" :fill="view === 'table' ? '#C8D0D6' : '#fff'"/>
        </svg>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="view = 'row'">
          <rect width="24" height="24" rx="6" :fill="view === 'table' ? '#C8D0D6' : '#fff'"/>
          <rect x="6" y="6" width="12" height="2" :fill="view === 'table' ? 'white' : '#C8D0D6'"/>
          <rect x="6" y="11" width="12" height="2" :fill="view === 'table' ? 'white' : '#C8D0D6'"/>
          <rect x="6" y="16" width="12" height="2" :fill="view === 'table' ? 'white' : '#C8D0D6'"/>
        </svg>
      </div>
    </div>
    <div v-if="projectsList && projectsList.length" class="projects__list">
      <div
        v-for="project in projectsList"
        :key="project.id"
        class="projects__item"
        :class="{'projects__item--row' : view === 'row'}"
      >
        <ProjectCard
          :view="view"
          :id="project.id"
          :name="project.name"
          :date="project.created_at"
          :img="project.image[0].src"
        />
      </div>
    </div>
    <div v-else>
      Проектов пока нет
    </div>
  </div>
</template>

<script>
import ProjectCard from '../components/ProjectCard'
export default {
  name: 'Projects',
  components: {
    ProjectCard,
  },
  data() {
    return {
      view: 'table',
      sort: 'asc',
    }
  },
  computed: {
    projectsList() {
      return this.$store.state.Project.projectsList
    },
  },
  created() {
    this.$store.dispatch('Project/getProjects', this.$route.query)
    this.sort = this.$route.query.sort_dir || 'asc'
  },
  methods: {
    changeSorting() {
      this.sort = this.sort === 'asc' ? 'desc' : 'asc'
      this.$router.push({query: { sort_dir: this.sort, sort_by: 'created_at'}})
      setTimeout(() => {
        this.$store.dispatch('Project/getProjects', this.$route.query)
      }, 0);
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.projects {
  padding: 3rem;
  width: 100%;

  &__sort {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $darkGrey;
    display: flex;
    align-items: center;
    cursor: pointer;

    &>img {
      display: block;
      margin-left: 0.8rem;
      transition: 0.3s ease-out;
      margin-top: 0.3rem;
    }

    &-reverse {
      &>img {
        transform: rotate(-180deg);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    &--left {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.6rem;
    margin-right: 15rem;
  }

  &__view {
    background: #C8D0D6;
    border-radius: 10px;
    height: 32px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0 2px;
      cursor: pointer;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    width: 100%;
  }

  &__item {
    padding: 0 1rem;
    width: calc(100% / 6);
    margin-bottom: 2rem;

    &--row {
      width: 100%;
    }
  }
}
</style>

<template>
  <div
    class="project-card"
    :class="{'project-card--row' : view === 'row'}"
    @click="goToProject"
  >
    <div class="project-card__picture">
      <img v-if="img" :src="img" alt="">
      <img v-else src="../assets/images/project.jpg" alt="">
    </div>
    <div class="project-card__info">
      <div class="project-card__title">
        {{ name }}
      </div>
      <el-popconfirm
        confirm-button-text="OK"
        cancel-button-text="Отмена"
        :icon="InfoFilled"
        icon-color="red"
        :title="`Удалить ${ name } ?`"
        @confirm="delProject"
      >
        <template #reference>
          <div class="project-card__delete">
            <img src="../assets/icons/icon-delete.svg" alt="">
          </div>
        </template>
      </el-popconfirm>
      <div class="project-card__date">
        {{ fixedDate(date) }}
      </div>
    </div>
  </div>
</template>

<script>
import { DateParser } from '@/mixin/date_parser'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'ProjectCard',
  mixins: [DateParser,AlertNotify],
  props: {
    id: {
      type: [String, Number],
      default: 1,
    },
    name: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: null,
    },
  },
  methods: {
    goToProject() {
      this.$router.push('/projects/' + this.id)
    },
    delProject() {
      this.$store.dispatch('Project/delProject', this.id)
        .then(() => {
          this.$store.dispatch('Project/getProjects')
          this.successNotify('Проект удалён')
        })
        .catch(err => {
          console.log(err.response.data.errors)
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.project-card {
  border: 1px solid $lightGrey;
  box-sizing: border-box;
  border-radius: 1rem;
  background: $basicWhite;
  padding: 0.4rem;
  display: block;
  transition: 0.3s ease-out;
  cursor: pointer;

  &--row {
    display: flex;

    .project-card{
      &__picture {
        width: 23rem;
        height: 9.2rem;
        margin-bottom: 0;
      }

      &__info {
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 2rem;
      }

      &__delete {
        bottom: 1rem;
        top: auto;
      }
    }
  }

  &:hover {
    filter: drop-shadow(0px 6px 64px rgba(0, 0, 0, 0.15));

    .project-card__delete {
      display: block;
    }
  }

  &__picture {
    width: 100%;
    height: 14rem;
    border-radius: 0.6rem;
    overflow: hidden;
    margin-bottom: 1.2rem;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    position: relative;
    padding: 0 1.6rem 1.2rem;
  }

  &__delete {
    position: absolute;
    right: 1.6rem;
    top: 0;
    cursor: pointer;
    display: none;
  }

  &__title {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 2.7rem;
    display: inline-block;
  }

  &__date {
    font-size: 1rem;
    line-height: 1.4rem;
    color: $darkGrey;
  }
}
</style>
